import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {AdDisplayType, AdProviderInterface} from "../ad-provider";
import {AdInterface, AdPositionName, AdPositions} from "../../ad-position/ad-position-map";
import {SklikComponent, sklikPositionId} from "../sklik/sklik.component";
import {AdSeznamPopupComponent} from "../../ad-seznam-popup/ad-seznam-popup.component";
import {BrowserLoggerService} from "../../../services/browser-logger.service";

export enum sspPositionId {
  smr = '121567', // 300x300
  smrMobil = '71221', // 480x480
  smrMobilClanek = '121577', // 480x480
  smrMobilClanek2 = '184821', // 111x111
  smrMobilText = '121572', // 111x111
  skyscraper = '71219', // 300x600
  mobilePopup = '71222',
  wallpaper = '323872', // 480x300
  txtSklik = '71314', // 560x315
  footerboard = '71220', // 970x310
  native = '121507', // 300x300
  nativeMobil = '112960', // 300x300
  leaderboard = '71217',
}

@Component({
  selector: 'app-ssp',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ssp.component.html',
  styleUrls: ['./ssp.component.css']
})
export class SspComponent implements AdProviderInterface {
  @Input() positionId: string | undefined;
  displayType: AdDisplayType;
  adPositions: AdPositions = new AdPositions();
  sssp: any | undefined;
  popupServed: boolean = false;
  recommendationServed: boolean = false;
  ads: { [id: string]: AdInterface } = {
    '71221': {
      id: sspPositionId.smrMobil,
      width: 300,
      height: 300,
      isMobile: true,
      isDesktop: false,
    },
    '121577': {
      id: sspPositionId.smrMobilClanek,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: false,
    },
    '71219': {
      id: sspPositionId.skyscraper,
      width: 300,
      height: 600,
      isMobile: false,
      isDesktop: true,
    },
    '71222': {
      id: sspPositionId.mobilePopup,
      width: 300,
      height: 100,
      isMobile: true,
      isDesktop: false,
    },
    '323872': {
      id: sspPositionId.wallpaper,
      width: 480,
      height: 300,
      isMobile: true,
      isDesktop: true,
    },
    '71314': {
      id: sspPositionId.txtSklik,
      width: 560,
      height: 315,
      isMobile: true,
      isDesktop: true,
    },
    '71220': {
      id: sspPositionId.footerboard,
      width: 970,
      height: 310,
      isMobile: true,
      isDesktop: true,
    },
    '71217': {
      id: sspPositionId.leaderboard,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '121567': {
      id: sspPositionId.smr,
      width: 480,
      height: 480,
      isMobile: false,
      isDesktop: true,
    },
    '184821': {
      id: sspPositionId.smrMobilClanek2,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '121507': {
      id: sspPositionId.native,
      width: 111,
      height: 111,
      isMobile: false,
      isDesktop: true,
    },
    '112960': {
      id: sspPositionId.nativeMobil,
      width: 111,
      height: 111,
      isMobile: true,
      isDesktop: false,
    },
    '121572': {
      id: sspPositionId.smrMobilText,
      width: 480,
      height: 300,
      isMobile: true,
      isDesktop: false,
    },
  }
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string | boolean,
    private readonly logger: BrowserLoggerService,
  ) {
    this.isBrowser = typeof platformId === 'string' ? isPlatformBrowser(platformId) : platformId;
    this.sssp = this.isBrowser ? (window as any).sssp : undefined;
    this.displayType = this.isBrowser ? (window.innerWidth > 767 ? AdDisplayType.desktop : AdDisplayType.mobile) : AdDisplayType.desktop;
  }

  serveAds(positions: Array<AdPositionName>): Array<AdPositionName> {
    if (!this.isBrowser) {
      return [];
    }

    let servedPositions: Array<AdPositionName> = [];
    let fallbackPositions: Array<AdPositionName> = [];
    const ads: Array<any> = [];
    positions.forEach((positionName: AdPositionName) => {
      const sspPosition = this.displayType == AdDisplayType.mobile ? this.adPositions.map[positionName].sspPositionMobile : this.adPositions.map[positionName].sspPositionDesktop;
      const fallbackPosition = this.displayType == AdDisplayType.mobile ? this.adPositions.map[positionName].sklikPositionMobile : this.adPositions.map[positionName].sklikPositionDesktop;
      if (positionName == AdPositionName.leaderboard && this.displayType == AdDisplayType.desktop) {
        const branding = this.serveBranding();
        if (branding) {
          ads.push(branding);
        }
        servedPositions.push(positionName);
        this.logger.log('Serving SSP position ' + positionName + '(' + sspPosition + ')');
      }
      else if (sspPosition) {
        const ad = this.ads[sspPosition];
        if (!ad) {
          this.logger.warn('MISSING SSP POSITION:' + sspPosition);
        }
        if ((this.displayType == AdDisplayType.desktop && ad.isDesktop) || (this.displayType == AdDisplayType.mobile && ad.isMobile)) {
          const adEl = document.getElementById("ssp-zone-" + ad.id);
          if (ad && adEl) {
            adEl.innerHTML = '';
            ads.push({zoneId: ad.id, id: "ssp-zone-" + ad.id, width: ad.width, height: ad.height});
            servedPositions.push(positionName);
            this.logger.log('Serving SSP position ' + positionName + '(' + sspPosition + ')');

            // disable serving to prevent double-serving
            //this.servePopup();
          }
          else if (ad) {
            this.logger.warn('MISSING SSP DOCUMENT TAG:' + sspPosition);
          }
        }
        else {
          // consider positions for different breakpoint as served to prevent repeated display attempts
          servedPositions.push(positionName);
        }
      }
      else if (fallbackPosition) {
        fallbackPositions.push(positionName);
      }
    });

    try {
      this.sssp.getAds(ads);
    }
    catch (error) {
      this.logger.error(error);
    }

    if (fallbackPositions) {
      let fallbackProvider = new SklikComponent(this.isBrowser, this.logger);
      servedPositions.push(...fallbackProvider.serveAds(fallbackPositions));
    }

    return servedPositions;

    // mobile
    /*var pozice = [];
    pozice.push({zoneId:71221, id:"ssp-zone-71221", width:480, height:480});
    pozice.push({zoneId:121577, id:"ssp-zone-121577", width:480, height:480});
    pozice.push({zoneId:184821, id:"ssp-zone-184821", width:480, height:480});

    // desktop
    /*var pozice = [];
    pozice.push({zoneId:71221, id:"ssp-zone-71221", width:300, height:300});
    pozice.push({zoneId:71217, id:"ssp-zone-71217", width:970, height:210});
    pozice.push({zoneId:121567, id:"ssp-zone-121567", width:480, height:480});
    pozice.push({zoneId:71219, id:"ssp-zone-71219", width:300, height:600});
    pozice.push({zoneId:71219, id:"ssp-zone-71219-2", width:300, height:600});
    pozice.push({zoneId:121507, id:"ssp-zone-121507", width:111, height:111});
    pozice.push({zoneId:71314, id:"ssp-zone-71314", width:560, height:315});
    pozice.push({zoneId:71218, id:"ssp-zone-71218", width:480, height:300});
    this.sssp.getAds(pozice);*/
  }

  serveBranding(): any | null {
    if (!this.isBrowser) {
      return null;
    }

    let brandedZone = null;
    if (window.innerWidth >= 1366) {
      const adEl = document.getElementById("branding");
      if (adEl) {
        adEl.innerHTML = '';
      }
      // vytvoří element pro branding hned za tagem <body>
      //document.body.insertAdjacentHTML('afterbegin', '<div id="sklik-zone-71217-branding"></div>');
      brandedZone = {
        "zoneId": 71217,
        "width": 2000,
        "id": "branding",
        "elements": [
          { id: "branding", width: 2000, height: 1400 }
        ]
      };
    }
    return brandedZone;
  }

  servePopup() {
    if (!this.popupServed) {
      const popupEl = document.getElementById("showPopUp");
      if (popupEl) {
        const configuration = [
          {
            type: "mobile",
            zoneId: 71222,
            breakPoint: 480,
            fromFeed: true
          }
        ];

        (window as any).getSznAdPopUp(configuration);
        this.popupServed = true;
      }
    }
  }

  serveRecommendations() {
    if (!this.recommendationServed && typeof (window as any).sznRecass !== "undefined") {
      const rcmEl = document.getElementById("showPopUp");
      if (rcmEl) {
        (window as any).sznRecass.initialized = false;
        (window as any).sznRecass.getRecommendationsByTags();
        this.recommendationServed = true;
      }
    }
  }
}
